import { useState, useEffect } from "react"; 
import "./LanguagePicker.css";
import logo from "../../../../assets/img/logos/durr.svg";
import locSvg from "../../../../assets/img/generic/localization.svg";
import { localization } from "../../../../assets/localization/localization";

interface FuncProps {
  languageHandler: (values: string) => void; 
  callbackTogglePicker: () => void; 
}



const LanguagePicker: React.FC<FuncProps> = (props: FuncProps ) => {  


const handleLanguageAndClose = (lang: string) => {
    props.callbackTogglePicker(); 
    props.languageHandler(lang);
}


  return (
    <div className="costom_row_wrapper"> 
        <div className="costom_row">
          <span
            className="navigation__link  "
            onClick={() => handleLanguageAndClose("en")}
          >
            English (EN)
          </span>
          <span
            className="navigation__link "
            onClick={() => handleLanguageAndClose("de")}
          >
            Deutsch (DE)
          </span>
        </div>   
    </div>
    
  );
};

export default LanguagePicker;
