import { Alert, AlertColor } from "@mui/material";

export enum REQUEST_TYPE {
    GET,
    POST,
    PUT
  }

  export enum ALERT_TYPE {
    Warning = "warning",
    Info = "info",
    Error = "error",
    Success = "success",
    Empty = ""  }
  
export enum EApiFunctionTypes {
  DISMISS_USER_RISK = "dismissUserRisk",
  CREATE_TAP = "createTap",
  PASSWORD_RESET = "passwordReset",
  UNKNOWN = "unknown"
}

  export type DownloadsInfo = {
    downloads: BlobModel[];
  }
  
  export type BlobModel = {
    displayName: string;
    id: string;
    children: BlobModel[];
    fileType: string;
    fileSize: number;
    modifiedDate: string;
  }
  
  export type TFunctionResult<T> = {
    status: "success" | "error" | "pending";
    errorMessage?: string;
    data?: T;
    dataType: EApiFunctionTypes;
  }

  export type UserPermission= {
    roles: USER_ROLES[]; 
  }

  export enum USER_ROLES {
    ADMIN = "Administrator",
    USER = "User", 
  }