import { useState, useEffect } from "react";
import { getFile } from "../../../../generic/services/ApiService";
import { BlobModel } from "../../../../generic/types";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import DownloadsFileIcon from "../downloadFileIcon/DownloadsFileIcon";
import { localization } from "../../../../generic/assets/localization/localization";

interface IInfoListBlobModel extends BlobModel {
  open: boolean;
}

export default function DownloadsInfoList(props: {
  blobs: BlobModel[];
  iconPadding: number;
}) {
  const [blobs, setBlobs] = useState([] as IInfoListBlobModel[]);

  useEffect(() => {
    setBlobs(
      props.blobs
        ? props.blobs.map((b) => {
            return {
              id: b.id,
              displayName: b.displayName,
              children: b.children,
              open: false,
              fileType: b.fileType,
              fileSize: b.fileSize,
              modifiedDate: b.modifiedDate,
            };
          })
        : ([] as IInfoListBlobModel[])
    );
  }, [props]);

  const handleClick = (key: string) => {
    setBlobs(
      blobs.map((b) => {
        if (b.displayName === key) {
          return { ...b, open: !b.open };
        } else {
          return b;
        }
      })
    );
  };

  const handleDownload = (fileId: string, key: string) => {
    getFile(fileId).then((downloadId) => {
      const apiUrl = process.env.REACT_APP_API_BASE_URL;
      const downloadUri = apiUrl + "/api/files/" + downloadId;
      window.location.href = downloadUri;
    });
  };


  const Dictionary : {[key: number]: string} = { 0: 'B', 1: 'KB', 2: 'MB', 3: 'GB', 4: 'TB', };

  const displayFileSize = (fileSize: number) => {
      let counter: number;
      counter = 0;
    while (fileSize > 1024) {
      fileSize = fileSize / 1024;
      counter++;
    }
    return fileSize.toFixed(2) + " " + Dictionary[counter];
  }

  const  formatModifiedDate = (date: string) => {
    const d = new Date(date); 
    return d.toLocaleDateString() + " " + d.toLocaleTimeString();
  }

  return (
    <div>
      {blobs?.map((b) => (
        <List
          sx={{ width: "100%", bgcolor: "background.paper" }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          key={b?.displayName}
        >
          <ListItemButton
            onClick={() => handleClick(b?.displayName)}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-arround",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-arround",
                width: "33%",
              }}
            >
              <ListItemIcon
                style={{
                  paddingLeft: props.iconPadding + "px",
                }}
              >
                <DownloadsFileIcon iconType={b.fileType} isDirectory= {b.children.length > 0}
                />
              </ListItemIcon>

              <ListItemText primary={b?.displayName} style={{
                  overflow:"hidden",
                }} />
              <Divider orientation="vertical" flexItem style={{
                  marginRight: "5px",
                }}/>
            </div>
             
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-arround",
                width: "33%",
              }}
            >
              <ListItemText primary={ b?.fileSize ? displayFileSize(b?.fileSize) : "Unkown"} style={{
                  overflow:"hidden",
                }}/>
              
              <Divider orientation="vertical" flexItem style={{
                  marginRight: "5px",
                }}/>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-arround",
                width: "33%",
              }}
            >
              <ListItemText primary={b?.modifiedDate ? formatModifiedDate(b?.modifiedDate) : "Unkown"} style={{
                  overflow:"hidden",
                }} />
 
            

             
              {b?.children.length > 0 ? (
                <div>{b?.open ? <ExpandLess /> : <ExpandMore />}</div>
              ) : (
                <></>
              )}
              {b?.id ? (
                <Box sx={{ m: 1, position: "relative" }}>
                  <Button style={{ 
                    backgroundColor:'#00468e',
                    }}
                    variant="contained"
                    onClick={() => handleDownload(b?.id, b?.displayName)}
                  >
                    {localization.downloadsPageDownloadbuttonlabel}
                  </Button>
                </Box>
              ) : (
                <></>
              )} 
              </div>
          </ListItemButton>

          <Collapse in={b?.open} timeout="auto">
            {b?.children ? (
              <DownloadsInfoList
                blobs={b?.children}
                iconPadding={props.iconPadding + 15}
              />
            ) : (
              <></>
            )}

            
          </Collapse>
        </List>
      ))}
    </div>
  );
}
