export const deStrings = {
    headerProducts : "produkte",
    headerIndustrySolutions : "branchenlösungen",
    headerService : "service",
    headerCareer : "karriere",
    headerCompany : "unternehmen",
    headerMedia : "media",
    headerMore : "dürr & more",
    headerSearch : "suche",
    headerDownloads : "downloads",
    footerSocialHeader:"Vernetzen Sie sich mit uns",
    footerSocialMedia:"social media",
    footerNewsLetter:"newsletter",
    footerContacts:"kontakt/standorte",
    footerGTC:"agb",
    footerDataProtection:"datenschutz",
    footerImprint:"impressum",
    footerSitemap:"sitemap",
    footerIntegrityLine:"integrity line",
    footerCookies:"cookies",
    footerLogoHoover:"Dürr - Leading in Production Efficiency",

    footerGTCLink:"https://www.durr.com/de/unternehmen/allgemeine-geschaeftsbedingungen",
    footerDataProtectionLink:"https://www.durr.com/de/datenschutz",
    footerImprintLink:"https://www.durr.com/de/impressum",
    footerSitemapLink:"https://www.durr.com/de/sitemap",
    footerIntegrityLineLink:"https://www.durr-group.com/de/investoren/corporate-governance/compliance",
    footerCookiesLink:"https://www.durr.com/de/cookies",

    downloadsPageDownloadbuttonlabel:"Herunterladen",

    downloadsPageInfoHeaderFileName:"Dateiname",
    downloadsPageInfoHeaderChangedDate:"Änderungsdatum",
    downloadsPageInfoHeaderFilesize:"Dateigröße",

    downloadsPageMainPageLoadingFiles:"Lade Dateien",
    downloadsPageMainPageNoPermission:"Keine Dateiberechtigungen gefunden",

    downloadsPageMainPageInfoBanner:"Sehr geehrte Kundin, sehr geehrter Kunde,\nwir begrüßen Sie im DXQ-Kundenportal von Dürr. Dieser Zugang steht Ihnen als Inhaber eines DXQ-Wartungsvertrags exklusiv zur Verfügung. Hier haben Sie vollen Zugriff auf die neuesten Features und Sicherheitsupdates für unsere Softwareprodukte.\nEine einfache Navigation ermöglicht Ihnen das unkomplizierte Herunterladen und Installieren neuer Updates. Als Ansprechpartner für den Software-Support stehen wir Ihnen bei Fragen und Problemen jederzeit zur Verfügung. Sie erreichen unser DXQsupport-Team telefonisch unter +49 7142 78-0 oder per E-Mail unter digital.support@durr.com.\nBei Fragen zum DXQ-Software-Portfolio können Sie sich telefonisch unter +49 7142 78-0 oder per E-Mail an digital.services@durr.com direkt an den DXQ-Vertrieb wenden.\nWeitere Informationen zum DXQ-Software-Portfolio und unseren anderen Produkten finden Sie auf unserer Website: https://www.durr.com/de/produkte/produktionssoftware-steuerung/dxq.\nMit freundlichen Grüßen\nIhr DXQ-Team",
    
    downloadsPageMainPageAlertUpdatingDB:"Datenbank aktualisiert.",
    downloadsPageMainPageAlertUpdatingDBError:"Bei der Aktualisierung der DB ist ein Fehler aufgetreten.",

    errorLandingPage:"Ein Fehler ist aufgetreten",
    loadingAuthentication:"Authentifizierung in Arbeit..."

    


  }