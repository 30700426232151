import { BlobModel } from "../../../../generic/types";
import List from "@mui/material/List";
import Divider from '@mui/material/Divider';
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText"; 
import DownloadsInfoList from '../downloadInfo/DownloadsInfoList'
import { localization } from "../../../../generic/assets/localization/localization";


export default function DownloadsInfoBody(props: { blobs: BlobModel[] }) {

    const headerStyle = {
        fontWeight: "bold", 
    };

  return (
    <div>
    <List
      sx={{ width: "100%", bgcolor: "background.paper" }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <ListItemButton
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-arround",
        }}
      > 
         <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-arround",
                width: "33%",
              }}
            >
        <ListItemText primary={localization.downloadsPageInfoHeaderFileName}  primaryTypographyProps={{ style: headerStyle }} />
        <Divider orientation="vertical" flexItem style={{
                  marginRight: "5px",
                }}/>
        </div>

        <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-arround",
                width: "33%",
              }}
            >
        <ListItemText primary={localization.downloadsPageInfoHeaderFilesize} primaryTypographyProps={{ style: headerStyle }} />
        
        <Divider orientation="vertical" flexItem style={{
                  marginRight: "5px",
                }} />
        </div>
        <ListItemText primary={localization.downloadsPageInfoHeaderChangedDate} primaryTypographyProps={{ style: headerStyle }} />
      </ListItemButton>
    </List>
    <Divider orientation="horizontal" flexItem />
    <DownloadsInfoList blobs={props.blobs} iconPadding={10}/> 
    </div>
 
  );
}
