import { Alert, AlertColor } from "@mui/material";
import useAlert from "../../hooks/useAlert";
import AlertTitle from "@mui/material/AlertTitle"; 

const AlertPopup = () => {
  const { text, type } = useAlert();
 

  if (text && type) {
     
    const altertType : AlertColor = type 
    return (
      <Alert
        variant="filled"
        severity={altertType}
        sx={{
            position: "absolute", 
             top: "150px",
             left: "50px", 
            zIndex: "2000",
        }}
      >
        <AlertTitle>{type}</AlertTitle>
        {text}
      </Alert>
    );
  } else {
    return <></>;
  }
};

export default AlertPopup;
