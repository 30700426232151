export const enStrings = {
    headerProducts : "products",
    headerIndustrySolutions : "industry solutions",
    headerService : "service",
    headerCareer : "career",
    headerCompany : "company",
    headerMedia : "media",
    headerMore : "dürr & more",
    headerSearch : "search",
    headerDownloads : "downloads",
    footerSocialHeader:"Connect with us",
    footerSocialMedia:"social media",
    footerNewsLetter:"newsletter",
    footerContacts:"contact/locations",
    footerGTC:"gtc",
    footerDataProtection:"data protection",
    footerImprint:"imprint",
    footerSitemap:"sitemap",
    footerIntegrityLine:"integrity line",
    footerCookies:"cookies",
    footerLogoHoover:"Dürr - Leading in Production Efficiency" ,

    footerGTCLink:"https://www.durr.com/en/company/general-terms-and-conditions",
    footerDataProtectionLink:"https://www.durr.com/en/data-protection",
    footerImprintLink:"https://www.durr.com/en/imprint",
    footerSitemapLink:"https://www.durr.com/en/sitemap",
    footerIntegrityLineLink:"https://www.durr-group.com/en/investor-relations/corporate-governance/compliance",
    footerCookiesLink:"https://www.durr.com/en/cookies",

    downloadsPageDownloadbuttonlabel:"download",

    downloadsPageInfoHeaderFileName:"File name",
    downloadsPageInfoHeaderChangedDate:"Modification date",
    downloadsPageInfoHeaderFilesize:"File size",

    downloadsPageMainPageLoadingFiles:"Loading files",
    downloadsPageMainPageNoPermission:"No file permissions found",

    downloadsPageMainPageInfoBanner:"Dear customer,\nWelcome to Dürr's DXQ customer portal. This access is exclusively available to you as the holder of a DXQ maintenance agreement. Here you have full access to the latest features and security updates for our software products.\nThe simple navigation makes it easy for you to download and install new updates. As your contact for software support, we are always available to help you with any questions or problems. You can reach our DXQsupport team by telephone on +49 7142 78-0 or via email: digital.support@durr.com.\nIf you have any questions about the DXQ software portfolio, you can contact the DXQ sales team directly by telephone on +49 7142 78-0 or by e-mail at digital.services@durr.com.\nFor more information on the DXQ software portfolio and our other products, please visit our website: https://www.durr.com/en/products/software-controls/dxq.\nKind regards,\nYour DXQ team",

    downloadsPageMainPageAlertUpdatingDB:"Updated DB.",
    downloadsPageMainPageAlertUpdatingDBError:"Something went wrong updating DB.",

    errorLandingPage:"An Error Occurred",
    loadingAuthentication:"Authentication in progress..."
}