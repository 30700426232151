import './DownloadsPage.css';
import { useState, useEffect } from 'react';
import useAlert from "../../../generic/hooks/useAlert";

import { getDownloads, updateDb, getPermissions } from '../../../generic/services/ApiService'
import { DownloadsInfo, BlobModel, ALERT_TYPE, UserPermission, USER_ROLES } from '../../../generic/types'
import DownloadsInfoBody from '../components/downloadInfoBody/downloadsInfoBody'
import { Button, Typography } from "@mui/material";
import { localization } from "../../../generic/assets/localization/localization";

// Msal imports
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { InteractionStatus, InteractionType, InteractionRequiredAuthError, AccountInfo } from "@azure/msal-browser";
import { loginRequest } from "../../../authConfig";
import { Loading } from "../components/msal/Loading";
import { ErrorComponent } from "../components/msal/ErrorComponent";

const Downloads = () => {
    const [download, setDownloads] = useState<DownloadsInfo>();
    const [permission, setPermission] = useState<UserPermission>();
    const [isLoading, setisLoading] = useState<boolean>(false);
    const { setAlert } = useAlert();
    const authRequest = {
        ...loginRequest
    };

    const { instance, inProgress } = useMsal();

    useEffect(() => {
        if (!download && inProgress === InteractionStatus.None) {
            setisLoading(true);
            getDownloads().then((dwl) => {
                setisLoading(false);
                setDownloads(dwl);
            }).catch((err) => {
                setisLoading(false);
                if (err instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect({
                        ...loginRequest,
                        account: instance.getActiveAccount() as AccountInfo
                    });
                }
            });

            getPermissions().then((perm) => {
                setPermission(perm);
            }).catch((err) => {
                setPermission({ roles: [USER_ROLES.USER] });
            });

        }
    }, [inProgress, download, instance]);

    const NoDownloadPermissions = () => {
        return <Typography variant="h6">{localization.downloadsPageMainPageNoPermission}</Typography>
    }

    const LoadingFilePermissions = () => {
        return <Typography variant="h6">{localization.downloadsPageMainPageLoadingFiles}</Typography>
    }

    const handleDbUpdate = () => {
        updateDb().then(() => {
            setAlert(localization.downloadsPageMainPageAlertUpdatingDB, ALERT_TYPE.Info);
        })
            .catch((err) => {
                console.log(err);
                setAlert(localization.downloadsPageMainPageAlertUpdatingDBError, ALERT_TYPE.Error);
            });
    };

    const UpdateDbButton = (buttonStyle: React.CSSProperties) => {

        if (permission?.roles.includes(USER_ROLES.ADMIN)) {
            return <Button
                variant="contained"
                style={{ backgroundColor: '#00468e' }}
                onClick={() => handleDbUpdate()}
            >
                Update Db
            </Button>
        } else {
            return null;
        }


    }

    const GetInfoBanner = () => {
        return <div className='pageInfoBanner'>

            {localization.downloadsPageMainPageInfoBanner.split('\n').map((item, i) => {

                return <span key={i} >{item.split(' ').map((link, j) => {

                    if (link.includes('https://')) {
                        return <a key={j} href={link.substring(0, link.length - 1)} target="_blank" rel="noreferrer">{' ' + link}</a>
                    }
                    if (link.includes('@')) {
                        return <a key={j} href={'mailto:' + link.substring(0, link.length - 1)} target="_blank" rel="noreferrer">{' ' + link}</a>
                    }
                    return <>{' ' + link}</>
                })}</span>

            })}

        </div>
    }

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest}
            errorComponent={ErrorComponent}
            loadingComponent={Loading}
        >

            <GetInfoBanner />

            <div style={{ display: 'flex', justifyContent: 'right', marginBottom: '10px' }}>
                <UpdateDbButton />
            </div>
            {isLoading && <LoadingFilePermissions />}
            {download && download.downloads && download.downloads.length === 0 && <NoDownloadPermissions />}
            {download && download.downloads && download?.downloads.length > 0 &&
                <div>
                    <DownloadsInfoBody blobs={download?.downloads as BlobModel[]} />
                </div>
            }
        </MsalAuthenticationTemplate>
    );
}



export default Downloads;