
import {DownloadsInfo, REQUEST_TYPE, UserPermission} from '../types';
import axios, {AxiosResponse} from "axios";
import { loginRequest } from "../../authConfig";
import { msalInstance } from "../../index";
  
export const sendRequest = async <T>(
  url: string,
  requestType: REQUEST_TYPE,
  config: any,
  body?: any
): Promise<AxiosResponse<T, any>> => {

  switch (requestType) {
    case REQUEST_TYPE.GET:
      return await axios.get<T>(url, config);
    case REQUEST_TYPE.POST:
      return await axios.post<T>(url, body, config);
    case REQUEST_TYPE.PUT:
      return await axios.put<T>(url, body, config);
    default:
      throw new Error("Invalid request type");
  }
};

const getToken = async (): Promise<string> => {
  const account = msalInstance.getActiveAccount();
  if (!account) {
      throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
  }

  const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: account
  });

  return response.accessToken;
}

export const getDownloads = async (): Promise<DownloadsInfo> => {
  const accessToken = await getToken();
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };

  return (
    await sendRequest<DownloadsInfo>(
      process.env.REACT_APP_API_BASE_URL + "/api/downloads",
      REQUEST_TYPE.GET,
      config
    )
  ).data;
};

export const getFile = async (fileId:string): Promise<string> => {
  const accessToken = await getToken();
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

  return (
    await sendRequest<string>(
      process.env.REACT_APP_API_BASE_URL + "/api/downloads/" + fileId.replaceAll("/", "%2F"),
      REQUEST_TYPE.GET,
      config
    )
  ).data;
};

export const getPermissions = async (): Promise<UserPermission> => {
  const accessToken = await getToken();
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

  return (
    await sendRequest<UserPermission>(
      process.env.REACT_APP_API_BASE_URL + "/api/permissions",
      REQUEST_TYPE.GET,
      config
    )
  ).data;
};



export const updateDb = async (): Promise<void> => {
  const accessToken = await getToken();
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };

  await sendRequest<void>(
    process.env.REACT_APP_API_BASE_URL + "/api/downloads/updatedb",
    REQUEST_TYPE.PUT,
    config
  ) 
};