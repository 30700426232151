import { useState, useMemo } from "react";
import { ALERT_TYPE } from "../types";
import React from "react";

type AlertContextType = {
  text: string;
  type: ALERT_TYPE;
  setAlert: (text: string, type: ALERT_TYPE) => void;
};

const ALERT_TIME = 5000;
const initialState = {
  text: "",
  type: ALERT_TYPE.Empty,
};

interface Props extends React.PropsWithChildren {
  children?: React.ReactNode;
}

export const AlertContext = React.createContext<AlertContextType>({
  text: "",
  type: ALERT_TYPE.Empty,
  setAlert(text, type) {
    let subText = initialState.text;
    let subType = initialState.type;
    if (text) subText = text;
    if (type) subType = type;
    setTimeout(() => {
      text = subText;
      type = subType;
    }, ALERT_TIME);
  },
});

export const AlertProvider: React.FC<Props> = ({ children }) => {
  const [text, setText] = useState("");
  const [type, setType] = useState(ALERT_TYPE.Empty);

  const setAlert = (text: string, type: ALERT_TYPE) => {
    setText(text);
    setType(type);

    setTimeout(() => {
      setText("");
      setType(ALERT_TYPE.Empty);
    }, ALERT_TIME);
  };

  const alertService: AlertContextType = useMemo(
    () => ({
      text,
      type,
      setAlert,
    }),
    [text, type, setAlert]
  );

  return (
    <AlertContext.Provider value={alertService}>
      {children}
    </AlertContext.Provider>
  );
};