import React from 'react';
import { useState } from 'react';
import { localization } from "./generic/assets/localization/localization";
import Navbar from './generic/components/layout/navigation/navbar/Navbar';
import Footer from '././generic/components/layout/footer/Footer';
import Downloads from '././pages/downloads/downloadsPage/DownloadsPage';
import AlertPopup from './generic/components/alert/AlertPopUp'
import Banner from './generic/assets/img/banners/190213_EEA_Stillframe2.jpg'
import { MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";

type AppProps = {
  pca: IPublicClientApplication;
};

function App({ pca }: AppProps) {

  const [lang, setLang] = useState("en");

  const updateLanguageState = (lang: string) => {
    setLang(lang);
  };

  localization.setLanguage(lang);

  return (
    <MsalProvider instance={pca}>
      <div className="content">
        <AlertPopup />
        <img
          src={Banner}
          alt=""
          style={{
            width: "100%",
            height: "60vh",
            objectFit: "cover",
            objectPosition: "top",
          }}
        />
        <Navbar languageHandler={updateLanguageState} />

        <div className="pageContainer">
          <Downloads />
        </div>
        <Footer />
      </div>
    </MsalProvider>
  );
}

export default App;
