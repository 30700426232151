import { useState, useEffect, useRef } from "react";
import "./Navbar.css";
import logo from "../../../../assets/img/logos/durr.svg";
import locSvg from "../../../../assets/img/generic/localization.svg";
import { localization } from "../../../../assets/localization/localization";
import  LanguagePicker  from "../../../../components/layout/navigation/languagePicker/LanguagePicker";


interface FuncProps {
  languageHandler: (values: string) => void;
}

const Navbar: React.FC<FuncProps> = (props: FuncProps) => {
  const [showPicker, setShowPicker] = useState(false);

  const  callbackTogglePicker = () => {
    setShowPicker(!showPicker); 
 }

  const focusRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if(showPicker && focusRef.current){
      focusRef.current.focus();
    }
    
  }, [showPicker]);

  return (
    <header className="navigation">
      <img alt="" className="logo-duerr" src={logo}></img> 
      <div className="banner">
      <a
            className="nav_li"
            href=""
            target="_self" 
          >
            <span>{localization.headerDownloads}</span>
          </a> 
          <div  tabIndex={0} onBlur={() => setShowPicker(false)}  ref={focusRef} className={showPicker ? 'LanguagePickerWrapper' : 'LanguagePickerWrapper hide'} >
              <LanguagePicker languageHandler = {props.languageHandler} callbackTogglePicker = {callbackTogglePicker}  /> 
          </div>
          

          <a
            className="nav_li" 
            target="_self" 
            onClick={callbackTogglePicker}
          >
            <img src={locSvg} className="svgicon_loc" ></img>
          </a> 

      </div>
           
    </header>
  );
};

export default Navbar;
