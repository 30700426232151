import "./DownloadsFileIcon.css";
import pngIcon from '../../../../generic/assets/img/icons/png-Image.png'; 
import directoryIcon from '../../../../generic/assets/img/icons/folder.png';
import pdfIcon from '../../../../generic/assets/img/icons/pdf-document.png';
import defaultIcon from '../../../../generic/assets/img/icons/unknown-document.png';
import zipIcon from '../../../../generic/assets/img/icons/zip-document.jpg'; 



const DownloadsFileIcon = ({ iconType, isDirectory }: { iconType: string, isDirectory: boolean }) => {
    const getIcon = (iconType: string) => {

        if (isDirectory){
            return <img alt="" src={directoryIcon} className="svgicon-downloadsInfo" ></img>;
        }

        switch (iconType) {
            case '.pdf':
                return <img alt="" src={pdfIcon} className="svgicon-downloadsInfo"  ></img>;
            case '.zip':
                return <img alt="" src={zipIcon} className="svgicon-downloadsInfo" ></img>; 
            case '.png':
                return <img alt="" src={pngIcon} className="svgicon-downloadsInfo" ></img>;                
            default: 
                return <img alt="" src={defaultIcon} className="svgicon-downloadsInfo" ></img>; 
                
        }
    };

    return <div>{getIcon(iconType)}</div>;
};

export default DownloadsFileIcon;


