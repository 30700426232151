import LocalizedStrings , { LocalizedStringsMethods } from "react-localization";
import { enStrings } from "./en_US";
import { deStrings } from "./de_DE"; 

export interface IStrings extends LocalizedStringsMethods
{
  headerProducts : string,
  headerIndustrySolutions : string,
  headerService : string,
  headerCareer : string,
  headerCompany : string,
  headerMedia : string,
  headerMore : string,
  headerSearch : string,
  headerDownloads : string,
  footerSocialHeader: string,
  footerSocialMedia: string,
  footerNewsLetter: string,
  footerContacts: string,
  footerGTC: string,
  footerDataProtection: string,
  footerImprint: string,
  footerSitemap: string,
  footerIntegrityLine: string,
  footerCookies: string,
  footerLogoHoover: string,
  footerGTCLink:string,
  footerDataProtectionLink:string,
  footerImprintLink:string,
  footerSitemapLink:string,
  footerIntegrityLineLink:string,
  footerCookiesLink:string,
  downloadsPageDownloadbuttonlabel:string
  downloadsPageInfoHeaderFileName:string,
  downloadsPageInfoHeaderChangedDate:string,
  downloadsPageInfoHeaderFilesize:string,
  downloadsPageMainPageLoadingFiles:string,
  downloadsPageMainPageNoPermission:string,
  downloadsPageMainPageInfoBanner:string,
  downloadsPageMainPageAlertUpdatingDB:string,
  downloadsPageMainPageAlertUpdatingDBError:string,
  errorLandingPage:string,
  loadingAuthentication:string,
}

export const localization: IStrings = new LocalizedStrings({
  en: enStrings,
  de: deStrings  
});
  