import './Footer.css';
import logo from '../../../assets/img/logos/durrgroup.svg'
import { localization } from '../../../assets/localization/localization';


const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">   
                <div className="row"> 
                <div className="col-md-12">
                    <ul className="footer__meta list-inline">
                        <li> 
                            <a href={localization.footerGTCLink}className="footerlink footerlink--gray" >
                                {localization.footerGTC}
                            </a>
                        </li>
                        <li>-</li>
                        <li>
                            <a href={localization.footerDataProtectionLink} className="footerlink footerlink--gray" >
                                {localization.footerDataProtection}
                            </a>
                        </li>
                        <li>-</li>
                        <li>
                            <a href={localization.footerImprintLink} className="footerlink footerlink--gray" target="_self">
                            {localization.footerImprint}
                            </a>
                        </li>
                        <li>-</li>
                        <li>
                            <a href={localization.footerSitemapLink}className="footerlink footerlink--gray" target="_self">
                            {localization.footerSitemap}
                            </a>
                        </li>
                        <li>-</li>
                        <li>
                            <a href={localization.footerIntegrityLineLink} className="footerlink footerlink--gray" >
                            {localization.footerIntegrityLine}
                            </a>
                        </li>
                        <li>-</li> 
                        <li>
                            <a href={localization.footerCookiesLink} className="footerlink footerlink--gray" target="_self">
                            {localization.footerCookies}
                            </a>
                        </li>
                    </ul>
                </div>

                </div>
                <div className="footer__logo">
                    <a href="https://www.durr-group.com" >
                        <span className="t3js-icon icon icon-size-default icon-state- icon-tx_udgpackage_logo_group" data-identifier="tx_udgpackage_logo_group">
                            <span className="icon-markup">
                                <img src={logo} alt="Dürr logo" title={localization.footerLogoHoover} className='svgicon svgicon-tx-udgpackage-logo-group' />
                            </span>
                        </span>
                    </a>
                </div>
            </div>
        </footer>
        
    );
}
 
export default Footer;